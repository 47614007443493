<template>
	<div class="main-content">
		<div class="row mt-4">
			<div class="col-12">
				<trip-history
					trip-type="active"
					:request-function="(params) => fetchTrips(params)"
					:show-trip-date="false"
					>
					<template slot="table-actions" slot-scope="props">
						<div class="d-flex align-items-center">
							<button
								v-if="props.data.item.start_trip && !props.data.item.end_trip && userHasPermission('trips005')"
								class="btn btn-danger btn-sm"
								@click.stop="props.endTrip()"
								>
								End Trip
							</button>
							<button
								v-if="!props.data.item.start_trip && !props.data.item.end_trip && userHasPermission('trips004')"
								class="btn btn-success btn-sm"
								@click.stop="props.startTrip()"
								>
								Start Trip
							</button>
							<button
								v-if="!userHasPermission('trips004') && !userHasPermission('trips005')"
								class="btn  btn-sm"
								>
								UnAuthorised
							</button>
						</div>
					</template>
				</trip-history>
			</div>
		</div>
	</div>
</template>

<script setup>
import { userHasPermission } from '@/composables/core/permissions'
</script>

<script>
import TripHistory from '@/components/modules/trips/TripHistory'

export default {
  components: {
    TripHistory
  },
  methods: {
    // TODO: create mixin for this
    async fetchTrips (params) {
      this.params = params
      const limit = params ? params.limit : 10
      const page = params ? params.page : 1

      const baseUrl = '/v1/trips/active'

      let url = `${baseUrl}?limit=${limit}&page=${page}&metadata=true&sort[created_at]=desc&`

      if (params?.from || params?.to) {
        if (params?.from) {
          url += `from=${params.from}&`
        }
        if (params?.to) {
          url += `to=${params.to}`
        }
      }
      if (params?.search) {
        url += `&search=${params.search}`
      }
      const result = await this.axios.get(url)
      const data = result.data.data
      return {
        data: data,
        meta_data : result.data?.metadata,
      }
    }
  }
}
</script>
